.profile-container {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
}

.profile-main {
    width: 650px;
    margin: auto 20px 30px 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar-img {
    max-width: 140px;
    border-radius: 50%;
    margin-top: 40px;
    border: 10px solid var(--text-color-special);
}

.name {
    color: var(--text-color-alt);
    font-family: var(--font-alt);
    font-size: 2rem;
    margin: 30px 0 0 0;
    font-weight: 700;
}

.login {
    color: var(--text-color-main);
    font-size: 1.5rem;
    margin: 20px 0 0 0;
    transition: ease 300ms;
}

.login:hover {
    color: var(--text-color-special);
}


.dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    padding: 0;
    margin: 20px 0 30px 0;
}

@media only screen and (max-width: 600px) {
    .dates {
        flex-direction: column;
        row-gap: 20px;
    }
}

.dates h3 {
    margin: 0;
}


.social-stats {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
}

.box {
    background-color: var(--bg-color-alt2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: ease 300ms;
}

.box:hover {
    background-color: var(--bg-color-alt);
}

.box h4 {
    margin: 0;
    padding-bottom: 5px;
    line-height: 0.9;
    font-size: 3.5rem;
    color: var(--text-color-alt);
}

.box h5 {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}