.footer-container{
    width: 100%;
    height: 200px;
}


.footer-main {
    height: 100%;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.footer-main .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 70%;
    padding: 0;
    margin: 60px 0 0 0;
}

.footer-main .icons i {
    color: var(--text-color-main);
    margin: 0;
    transition: ease 300ms;
}

.footer-main .icons i:hover {
    color: var(--text-color-special);
}

.footer-main h5 {
    font-weight: 400;
    font-size: 0.9em;
}

.footer-main h5 span {
    font-weight: 700;
}

.footer-main h5 span:hover {
    color: var(--text-color-special);
}