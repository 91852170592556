.top-text {
    font-size: 15px;
    /* font-weight: 600; */
    fill: var(--text-color-main);
    z-index: 1000;
}

text {
    font-size: 0.65rem;
    fill: var(--text-color-main);
}

.pizza {
    border: 2px solid white;
}

.legend-text {
    font-size: 0.75rem;
    fill: var(--text-color-main);
}

.tooltipTopLanguages,
.tooltipMostStarred,
.tooltipStarsLanguage {
    position: fixed;
    pointer-events: none;
    background: rgb(107, 107, 107);
    padding: 8px;
    border-radius: 4px;
}

.tooltipTopLanguages p,
.tooltipStarsLanguage p,
.tooltipMostStarred p {
    color: #fff;
    line-height: 1.2;
    margin: 0;
}