.repos-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.repos-main {
    margin: 60px 20px;
    width: 1200px;
}

label {
    font-size: 1.5rem;
    /* margin: 0 100px; */
}

.input-selection, .section-title {
    background-color: transparent;
    font-size: 1.5rem;
    color: var(--text-color-main);
    border: 0;
    /* margin: 60px 6px 40px 6px; */
    margin-bottom: 40px;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(240px, 1fr) );
    row-gap: 20px;
    column-gap: 20px;
}



@media only screen and (max-width: 600px) {
    .input-selection {
        font-size: 1.rem;
    }
}