.project-container {
    background-color: var(--bg-color-alt2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: ease 300ms;
}

.project-container:hover {
    background-color: var(--bg-color-alt);
}

.project-main {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    row-gap: 10px;
    font-size: 0.9rem;
    margin: 15px;
}

.top-line {
    /* background-color: blue; */
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.middle-line {
    /* background-color: orange; */
    margin: 0 0 0 0;
    height: 70px;
    overflow: hidden;
    /* justify-self: stretch;
    align-self: stretch; */
}

.bottom-line {
    /* background-color: red; */
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    float: inline-end;
}

.project-title {
    font-family: var(--font-alt);
    color: var(--text-color-alt);
    font-size: 1rem;
    margin: 0 0 0 0;
    justify-self: start;
}

.link-icon {
    margin: 0 0 0 0;
    /* background-color: blue; */
    justify-self: end;
}

.link-icon:hover {
    color: var(--text-color-special);
}

i {
    margin: 0;
    padding: 0;
}

.project-description {
    margin: 0 0 0 0;
}

.project-language {
    margin: 0 0 0 0;
}

.project-stars {
    margin: 0 0 0 0;
}

.project-forks {
    margin: 0 0 0 0;
}

.project-size {
    margin: 0 0 0 0;
}

