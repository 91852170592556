.chart-container {
    background-color: var(--bg-color-alt2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: ease 300ms;
}

.chart-main {
    /* min-height: 300px; */
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chart-title {
    color: var(--text-color-alt);
    margin: 0;
    font-size: 1.2rem;
}