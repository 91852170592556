.stats-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.stats-main {
    margin: auto 20px;
    width: 1000px;
}

.section-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 60px 6px 40px 6px;
}

.charts-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
}

.fa-spinner {
    animation-iteration-count: infinite;
    animation-name: rotation;
    animation-duration: 3s;
    animation-timing-function: linear;
}

@keyframes rotation {
    100% {
        transform: rotate(360deg)
    }
}